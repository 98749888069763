import React, { useEffect, useRef, useState, useCallback } from 'react';

// Turnstile types
interface TurnstileInstance {
  render: (
    element: HTMLElement,
    options: TurnstileOptions
  ) => string;
  reset: (widgetId: string) => void;
  remove: (widgetId: string) => void;
  getResponse: (widgetId: string) => string | undefined;
}

interface TurnstileOptions {
  sitekey: string;
  callback: (token: string) => void;
  'error-callback'?: () => void;
  'expired-callback'?: () => void;
  'timeout-callback'?: () => void;
  theme?: 'light' | 'dark' | 'auto';
  appearance?: 'always' | 'execute' | 'interaction-only';
  size?: 'normal' | 'compact';
  retry?: 'auto' | 'never';
  retryInterval?: number;
  language?: string;
  tabindex?: number;
  'refresh-expired'?: 'auto' | 'manual' | 'never';
  execution?: 'render' | 'execute';
}

declare global {
  interface Window {
    turnstile?: TurnstileInstance | undefined;
    onTurnstileLoad?: () => void;
  }
}

interface CloudflareTurnstileProps {
  siteKey: string;
  onSuccess: (token: string) => void;
  onError?: () => void;
  onExpire?: () => void;
  onTimeout?: () => void;
  theme?: 'light' | 'dark' | 'auto';
  appearance?: 'always' | 'execute' | 'interaction-only';
  size?: 'normal' | 'compact';
  language?: string;
  tabIndex?: number;
  retry?: 'auto' | 'never';
  refreshExpired?: 'auto' | 'manual' | 'never';
  execution?: 'render' | 'execute';
  retryInterval?: number;
  containerClassName?: string;
}

const CloudflareTurnstile: React.FC<CloudflareTurnstileProps> = ({
  siteKey,
  onSuccess,
  onError,
  onExpire,
  onTimeout,
  theme = 'auto',
  appearance = 'always',
  size = 'normal',
  language,
  tabIndex,
  retry = 'auto',
  refreshExpired = 'auto',
  execution = 'render',
  retryInterval = 8000,
  containerClassName,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const widgetIdRef = useRef<string | null | undefined>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const mountedRef = useRef(false);

  const resetCaptcha = useCallback(() => {
    if (window.turnstile && widgetIdRef.current) {
      window.turnstile.reset(widgetIdRef.current);
      setIsVerified(false);
    }
  }, []);

  const renderTurnstile = useCallback(() => {
    if (!window.turnstile || !containerRef.current || widgetIdRef.current) return;

    try {
      widgetIdRef.current = window.turnstile.render(containerRef.current, {
        sitekey: siteKey,
        callback: (token: string) => {
          setHasError(false);
          setIsVerified(true);
          onSuccess(token);
        },
        'error-callback': () => {
          setHasError(true);
          setIsVerified(false);
          onError?.();
          if (retry === 'auto') {
            setTimeout(resetCaptcha, retryInterval);
          }
        },
        'expired-callback': () => {
          setHasError(false);
          setIsVerified(false);
          onExpire?.();
          if (refreshExpired === 'auto') {
            resetCaptcha();
          }
        },
        'timeout-callback': () => {
          setHasError(true);
          setIsVerified(false);
          onTimeout?.();
          if (retry === 'auto') {
            setTimeout(resetCaptcha, retryInterval);
          }
        },
        theme,
        appearance,
        size,
        language,
        tabindex: tabIndex,
        'refresh-expired': refreshExpired,
        execution,
      });
    } catch (error) {
      console.error('Failed to render Turnstile:', error);
      setHasError(true);
      onError?.();
    }
  }, [
    siteKey,
    onSuccess,
    onError,
    onExpire,
    onTimeout,
    theme,
    appearance,
    size,
    language,
    tabIndex,
    retry,
    refreshExpired,
    execution,
    retryInterval,
    resetCaptcha,
  ]);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!siteKey) {
      console.error('CloudflareTurnstile: siteKey is required');
      return;
    }

    if (typeof window === 'undefined') return;

    const scriptId = 'cf-turnstile-script';
    let script = document.getElementById(scriptId) as HTMLScriptElement;

    if (!script) {
      script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onTurnstileLoad';
      script.async = true;
      script.defer = true;

      window.onTurnstileLoad = () => {
        if (mountedRef.current) {
          setIsScriptLoaded(true);
        }
      };

      document.body.appendChild(script);
    } else if (window.turnstile) {
      setIsScriptLoaded(true);
    }

    return () => {
      const container = containerRef.current;
      if (window.turnstile && container && widgetIdRef.current) {
        window.turnstile.remove(widgetIdRef.current);
        widgetIdRef.current = null;
      }
    };
  }, [siteKey]);

  useEffect(() => {
    if (isScriptLoaded && !widgetIdRef.current) {
      renderTurnstile();
    }
  }, [isScriptLoaded, renderTurnstile]);

  return (
    <div
      ref={containerRef}
      className={`turnstile-container ${containerClassName || ''} ${
        isVerified ? 'verified' : ''
      }`}
      data-error={hasError}
      data-verified={isVerified}
      aria-invalid={hasError}
    />
  );
};

export default React.memo(CloudflareTurnstile);