import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  setPersistence,
  FacebookAuthProvider,
  AuthError as FirebaseAuthError,
  onAuthStateChanged,
  User,
  signOut,
} from 'firebase/auth';
import { initializeApp, getApps } from 'firebase/app';
import { toast, ToastContainer } from 'react-toastify';
import { motion } from 'framer-motion';
import { ThreeDots } from 'react-loader-spinner';
import { FaFacebook } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { PiEye, PiEyeSlash, PiWarningCircle } from 'react-icons/pi';
import { useTheme } from 'next-themes';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Link from 'next/link';
import Turnstile from '../components/CloudflareTurnstile';

// Session timeout in milliseconds (30 minutes)
const SESSION_TIMEOUT = 30 * 60 * 1000;

// Firebase configuration with error handling
const initializeFirebase = () => {
  const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY!,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN!,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID!,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET!,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID!,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID!,
  };

  if (!getApps().length) {
    try {
      const app = initializeApp(firebaseConfig);
      const auth = getAuth(app);
      setPersistence(auth, browserSessionPersistence).catch((error) => {
        console.error('Persistence setting error:', error);
      });
      return { auth, app };
    } catch (error) {
      console.error('Firebase initialization error:', error);
      throw new Error('Failed to initialize Firebase');
    }
  }
  return { auth: getAuth(), app: getApps()[0] };
};

// Initialize Firebase and Auth Providers
const { app } = initializeFirebase();
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

// Configure providers
googleProvider.setCustomParameters({
  prompt: 'select_account',
});

facebookProvider.setCustomParameters({
  display: 'popup',
});

// Define custom AuthError type
interface AuthError extends FirebaseAuthError {
  code: string;
  message: string;
}

// Custom hook for Firebase auth state with session management
const useFirebaseAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const router = useRouter();

  useEffect(() => {
    let sessionTimeout: NodeJS.Timeout;
    let activityTimeout: NodeJS.Timeout;

    const resetSession = () => {
      if (sessionTimeout) clearTimeout(sessionTimeout);
      if (activityTimeout) clearTimeout(activityTimeout);

      sessionTimeout = setTimeout(async () => {
        if (auth.currentUser) {
          await signOut(auth);
          toast.info('Session expired. Please login again.');
          router.push('/login');
        }
      }, SESSION_TIMEOUT);
    };

    const handleUserActivity = () => {
      if (activityTimeout) clearTimeout(activityTimeout);
      activityTimeout = setTimeout(resetSession, 1000); // Debounce activity tracking
    };

    // Track user activity
    const activityEvents = ['mousedown', 'keydown', 'scroll', 'touchstart'];
    activityEvents.forEach((event) => {
      window.addEventListener(event, handleUserActivity);
    });

    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        setUser(user);
        setLoading(false);
        if (user) {
          resetSession();
          // Store login timestamp
          localStorage.setItem('loginTimestamp', Date.now().toString());
        }
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );

    // Handle redirect result for Facebook login
    getRedirectResult(auth)
      .then((result) => {
        if (result && result.user) {
          toast.success('Successfully logged in!');
          router.push('/dashboard');
        }
      })
      .catch((error) => {
        if ((error as AuthError).code) {
          handleAuthError(error as AuthError);
        } else {
          console.error('Unexpected error:', error);
          toast.error('An unexpected error occurred');
        }
      });

    // Check for session timeout on component mount
    const checkExistingSession = () => {
      const loginTimestamp = localStorage.getItem('loginTimestamp');
      if (loginTimestamp) {
        const sessionAge = Date.now() - Number(loginTimestamp);
        if (sessionAge >= SESSION_TIMEOUT) {
          signOut(auth);
          localStorage.removeItem('loginTimestamp');
          toast.info('Session expired. Please login again.');
          router.push('/login');
        } else {
          resetSession();
        }
      }
    };

    checkExistingSession();

    return () => {
      unsubscribe();
      activityEvents.forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
      });
      if (sessionTimeout) clearTimeout(sessionTimeout);
      if (activityTimeout) clearTimeout(activityTimeout);
    };
  }, [router]);

  return {
    user,
    loading,
    error,
    isAuthenticated: !!user,
  };
};

// Form validation schema
const loginSchema = yup.object().shape({
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
});

interface LoginFormInputs {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const router = useRouter();
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState<string | null>(null);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const { isAuthenticated, loading: authLoading } = useFirebaseAuth();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    if (isAuthenticated && !authLoading) {
      router.push('/dashboard');
    }
  }, [isAuthenticated, authLoading, router]);

  const handleAuthError = (error: AuthError) => {
    const errorMessages: { [key: string]: string } = {
      'auth/user-not-found': 'No account found with this email',
      'auth/wrong-password': 'Invalid password',
      'auth/too-many-requests': 'Too many failed attempts. Please try again later',
      'auth/network-request-failed': 'Network error. Please check your connection',
      'auth/popup-closed-by-user': 'Login popup was closed. Please try again',
      'auth/cancelled-popup-request': 'Login popup was cancelled. Please try again',
      'auth/popup-blocked': 'Login popup was blocked. Please allow popups and try again',
      'auth/account-exists-with-different-credential':
        'An account already exists with the same email address but different sign-in credentials',
      'auth/invalid-email': 'Invalid email address',
      'auth/user-disabled': 'This account has been disabled',
      'auth/operation-not-allowed': 'This login method is not allowed',
    };

    const errorMessage =
      errorMessages[error.code] || error.message || 'An unexpected error occurred';
    setAuthError(errorMessage);
    toast.error(errorMessage);
  };

  const handleSocialLogin = async (provider: GoogleAuthProvider | FacebookAuthProvider) => {
    try {
      setIsLoading(true);
      setAuthError(null);
      await setPersistence(auth, browserSessionPersistence); // Changed to session persistence

      if (provider.providerId === FacebookAuthProvider.PROVIDER_ID) {
        // Use redirect for Facebook login
        await signInWithRedirect(auth, provider);
      } else {
        // Use popup for other providers (e.g., Google)
        const result = await signInWithPopup(auth, provider);
        toast.success('Successfully logged in!');
        router.push('/dashboard');
      }
    } catch (error: unknown) {
      if ((error as AuthError).code) {
        handleAuthError(error as AuthError);
      } else {
        console.error('Unexpected error:', error);
        toast.error('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleTurnstileVerification = (token: string) => {
    setTurnstileToken(token);
  };

  const onSubmit = async (data: LoginFormInputs) => {
    if (!turnstileToken) {
      toast.error('Please complete the CAPTCHA verification');
      return;
    }

    try {
      setIsLoading(true);
      setAuthError(null);

      const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);

      const verificationResponse = await fetch('/api/verify-auth', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: await userCredential.user.getIdToken(),
          turnstileToken,
        }),
      });

      if (!verificationResponse.ok) {
        throw new Error('Backend verification failed');
      }

      toast.success('Successfully logged in!');
      router.push('/dashboard');
    } catch (error: unknown) {
      handleAuthError(error as AuthError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Head>
        <title>Login</title>
      </Head>
      <div
        className={`min-h-screen flex flex-col ${
          theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'
        }`}
      >
        <Navbar lng="en" />
        <main className="flex-grow flex flex-col items-center justify-center py-16 px-4 sm:px-6 lg:px-8 mt-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className={`max-w-md w-full space-y-8 ${
              theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
            } p-8 rounded-xl shadow-lg`}
          >
            <div>
              <h2 className="text-center text-3xl font-extrabold mb-2">Welcome Back</h2>
              <p className="text-center text-sm">
                Don&apos;t have an account?{' '}
                <Link href="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Sign up here
                </Link>
              </p>
            </div>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span
                    className={`px-2 ${
                      theme === 'dark' ? 'bg-gray-800' : 'bg-white'
                    } text-gray-500`}
                  >
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-2 gap-3">
                <button
                  type="button"
                  onClick={() => handleSocialLogin(googleProvider)}
                  disabled={isLoading}
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <FcGoogle className="w-5 h-5" />
                  <span className="ml-2">Google</span>
                </button>

                <button
                  type="button"
                  onClick={() => handleSocialLogin(facebookProvider)}
                  disabled={isLoading}
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <FaFacebook className="w-5 h-5" style={{ color: '#4267B2' }} />
                  <span className="ml-2">Facebook</span>
                </button>
              </div>
            </div>

            <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    type="email"
                    autoComplete="email"
                    {...register('email')}
                    className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                      errors.email ? 'border-red-300' : 'border-gray-300'
                    } placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                    placeholder="Email address"
                  />
                  {errors.email && (
                    <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>
                  )}
                </div>
                <div className="relative">
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    {...register('password')}
                    className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                      errors.password ? 'border-red-300' : 'border-gray-300'
                    } placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                    placeholder="Password"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <PiEyeSlash className="h-5 w-5 text-gray-400" />
                    ) : (
                      <PiEye className="h-5 w-5 text-gray-400" />
                    )}
                  </button>
                  {errors.password && (
                    <p className="mt-2 text-sm text-red-600">{errors.password.message}</p>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <Link
                    href="/forgot-password"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <div>
                <Turnstile
                  siteKey={process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY!}
                  onSuccess={handleTurnstileVerification}
                />
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {isLoading ? (
                    <ThreeDots color="#ffffff" height={24} width={24} />
                  ) : (
                    'Sign in'
                  )}
                </button>
              </div>

              {authError && (
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <PiWarningCircle className="h-5 w-5 text-red-400" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">{authError}</p>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </motion.div>
        </main>
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};

export default LoginPage;
function handleAuthError(error: AuthError) {
  const errorMessages: { [key: string]: string } = {
    'auth/user-not-found': 'No account found with this email',
    'auth/wrong-password': 'Invalid password',
    'auth/too-many-requests': 'Too many failed attempts. Please try again later',
    'auth/network-request-failed': 'Network error. Please check your connection',
    'auth/popup-closed-by-user': 'Login popup was closed. Please try again',
    'auth/cancelled-popup-request': 'Login popup was cancelled. Please try again',
    'auth/popup-blocked': 'Login popup was blocked. Please allow popups and try again',
    'auth/account-exists-with-different-credential':
      'An account already exists with the same email address but different sign-in credentials',
    'auth/invalid-email': 'Invalid email address',
    'auth/user-disabled': 'This account has been disabled',
    'auth/operation-not-allowed': 'This login method is not allowed',
  };

  const errorMessage =
    errorMessages[error.code] || error.message || 'An unexpected error occurred';
  toast.error(errorMessage);
}

// Removed duplicate function implementation
